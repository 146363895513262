import { RuleEngine } from '@natural-apptitude/coreo-conditions';
import bbox from '@turf/bbox';
import { startOfDay } from 'date-fns';
import validate from 'validate.js';

import { ionToCoreoDatetime } from '../../services/utils.service';
import ValidationEngine from '../../services/validation.service';
import { CoreoAssociateMap, CoreoAttribute, CoreoAttributeConditionRule, CoreoAttributeConditions, CoreoForm, CoreoRecord, CoreoRecordSyncStatus } from '../../types';
import { FormBlock, FormQuestion } from '../../types/forms.types';
import { Actions, TypeKeys } from '../actions';

import { FormsUpdateRecordGeometryAction } from './forms.actions';
import { buildRecordClone } from '../records/records.actions';

export interface FormSection {
  visible: boolean;
  completed: boolean;
  blocks: FormBlock[];
  constraints: any[];
}

export interface FormState extends CoreoForm {
  ready: boolean;
  dirty: boolean;
  finished: boolean;
  record: CoreoRecord;
  sections: FormSection[];
  uuid: string;
  attributes: CoreoAttribute[];
  sectionId: number;
  nextSectionId: number;
  prevSectionId: number;
  subformIdx: number;
  subformAttributeId: number;
}

export interface FormsState {
  forms: FormState[];
}

const initialState: FormsState = {
  forms: []
};

const emptyRecord = (): CoreoRecord => ({
  id: null,
  projectId: null,
  surveyId: null,
  state: null,
  formId: null,
  geometry: null,
  geometryCenter: null,
  feature: null,
  syncState: CoreoRecordSyncStatus.PENDING_UPDATE,
  data: {},
  // media: [],
  attachments: [],
  associates: {},
  userId: null
});

// Deleting previous input leaves an empty string, this should be considered undefined for validation
validate.isDefined = (value: any) => {
  return value !== null && value !== undefined && value !== '';
}

/**
 * Evaluate an individual conditional rule
 * @param block A block to evaluate (the target of a rule, not the source!)
 * @param record The record
 * @param rule A rule to evaluate
 */
export const evaluateRule = (block: FormBlock, record: CoreoRecord, rule: CoreoAttributeConditionRule): boolean => {

  if (!block) {
    // Attribute could not be found, or is not used by any question; rule is invalid
    return null;
  }
  return RuleEngine.evaluateRule(rule, block.type, record.data[rule.path]);
};

/**
 * Evaluate a conditional ruleset
 * @param conditions A conditional ruleset to evaluate
 * @returns The success state of the evaluation
 */
export const evaluateConditions = (conditions: CoreoAttributeConditions, attributes: CoreoAttribute[], record: CoreoRecord): boolean => {
  if (!(conditions && conditions.rules && conditions.rules.length > 0)) {
    return true;
  }

  for (const rule of conditions.rules) {

    const target = rule.path ? attributes.find(a => a.path === rule.path) as FormBlock : null;

    const result = evaluateRule(target, record, rule);
    if (result === null) {
      // Rule is invalid, ignore
      continue;
    }

    if (!result && !conditions.any) {
      // Conditions require all rules to hold, and this one failed
      return false;
    }
    if (result && conditions.any) {
      // Conditions require any rule to hold, and this one passed
      return true;
    }
  }

  // If we reach here, either:
  // - *all* rules are required and none failed: success
  // - *any* rule is required and none passed: failure
  return !conditions.any;
};

const updateAssociationSyncState = (associates: CoreoAssociateMap, attributes: CoreoAttribute[], state?: CoreoRecordSyncStatus): CoreoAssociateMap => {

  const updatedAssociates: CoreoAssociateMap = {};
  for (const attributeId in associates) {
    const attribute = attributes.find(a => a.id === parseInt(attributeId));
    if (attribute.questionType === 'child') {
      updatedAssociates[attributeId] = associates[attributeId].map(associate => ({
        ...associate,
        syncState: state ?? associate.origSyncState ?? CoreoRecordSyncStatus.PENDING_UPDATE,
        origSyncState: typeof state === 'undefined' ? null : associate.syncState,
        associates: updateAssociationSyncState(associate.associates, attributes, state)
      }));
    }
  }
  return {
    ...associates,
    ...updatedAssociates
  }
}

const reducer = (state = initialState, action: Actions): FormsState => {

  const isQuestion = (arg: any): arg is FormQuestion => {
    return arg.type !== null;
  };

  const processSections = (f: FormState): FormState => {
    const { record, sections, sectionId } = f;

    const formRecord: CoreoRecord = {
      ...record,
      data: {
        ...record.data
      },
      associates: {
        ...record.associates
      },
      attachments: [
        ...record.attachments
      ]
    };

    const updatedSections = sections.map((section, idx) => {

      const blocks = section.blocks.map((block: FormBlock, index: number) => {
        let isVisible = true;

        // Only evaluate conditions if it's not required
        if ((isQuestion(block)) || block.questionType === 'child' || block.questionType === 'text' || block.questionType === 'association' || block.questionType === 'geometry') {
          isVisible = evaluateConditions(block.conditions, f.attributes, formRecord);
        }

        const constraints = section.constraints[index];
        // If this block is now visible, and it has an auto flag, fill it in
        if ((idx === sectionId) && isVisible && block.config?.auto && !formRecord.data[block.path]) {
          switch (block.type) {
            case 'datetime': {
              formRecord.data[block.path] = ionToCoreoDatetime(new Date().toISOString());
              break;
            }
            case 'date': {
              const start = startOfDay(new Date());
              const year = start.getFullYear();
              const month = (start.getMonth() + 1).toString().padStart(2, '0');
              const date = start.getDate().toString().padStart(2, '0');
              formRecord.data[block.path] = `${year}-${month}-${date}T00:00:00.000Z`;
              break;
            }
            default: {
              console.error('DO NOT KNOW HOW TO AUTO THIS TYPE');
            }
          }
        } else if (!isVisible && block.path && typeof formRecord.data[block.path] !== 'undefined') {
          // Delete the value if it's no longer visible
          delete formRecord.data[block.path];
        } else if (!isVisible && block.path && formRecord.associates[block.id]) {
          delete formRecord.associates[block.id];
        } else if (!isVisible && block.questionType === 'geometry') {
          formRecord.geometry = null;
          formRecord.geometryCenter = null;
        } else if (!isVisible && block.type === 'attachment') {
          formRecord.attachments = formRecord.attachments.filter(a => a.attributeId !== block.id);
        }

        return {
          ...block,
          error: isVisible ? ValidationEngine.validateAnswer(block, constraints, formRecord) : null,
          isVisible
        };
      });

      return {
        ...section,
        blocks,
        visible: blocks.some(b => b.isVisible),
        completed: blocks.every(b => b.error === null)
      };
    });

    return {
      ...f,
      sections: updatedSections,
      record: formRecord
    }
  };

  const getNextActiveSection = (currentId: number, sections: FormSection[]): number => {
    for (let i = currentId + 1; i < sections.length; i++) {
      if (sections[i].visible) {
        return i;
      }
    }
    return null;
  };

  const getPrevActiveSection = (currentId: number, sections: FormSection[]): number => {
    for (let j = currentId - 1; j >= 0; j--) {
      if (sections[j].visible) {
        return j;
      }
    }
    return null;
  };

  const updateCurrentRecord = (_state: FormsState, cb: (record: CoreoRecord) => Partial<CoreoRecord>): FormsState => {
    const [currentForm] = _state.forms.slice(-1);

    const record = {
      ...currentForm.record,
      ...cb(currentForm.record)
    };

    const updatedForm: FormState = {
      ...currentForm,
      record
    };

    const { sections: updatedSections, record: updatedRecord } = processSections(updatedForm);
    return {
      forms: [
        ..._state.forms.slice(0, -1),
        {
          ...updatedForm,
          record: updatedRecord,
          sections: updatedSections,
          nextSectionId: getNextActiveSection(updatedForm.sectionId, updatedSections),
          prevSectionId: getPrevActiveSection(updatedForm.sectionId, updatedSections),
          ready: updatedSections.every(section => section.completed),
          dirty: true
        }
      ]
    };
  };

  const initForm = (form: CoreoForm, attributes: CoreoAttribute[], projectId: number, record: CoreoRecord, subformAttributeId: number, subformIdx: number, state?: FormState): FormState => {
    const now = new Date();
    const formRecord: CoreoRecord = record ? {
      ...record,
      syncState: CoreoRecordSyncStatus.PENDING_UPDATE,
      updatedAt: now.toISOString(),
      timestamp: now.valueOf(),
      formId: record.surveyId,
      feature: record.geometry ? {
        type: 'Feature',
        geometry: record.geometry,
        bbox: bbox(record.geometry),
        properties: {}
      } : null
    } : {
      ...emptyRecord(),
      projectId,
      formId: form.id,
      surveyId: form.id,
      id: null
    };

    // Build the sections
    const sections = attributes
      .filter(a => a.visible) // There can be invisible expressions questions present in a form
      .reduce((acc: CoreoAttribute[][], attribute: CoreoAttribute) => {
        (acc[attribute.sectionIdx] = acc[attribute.sectionIdx] || []).push(attribute);
        return acc;
      }, []) // Ensure location questions have their own sections
      .reduce((acc: CoreoAttribute[][], formAttributes: CoreoAttribute[]) => {
        const geometryQuestionIdx = formAttributes.findIndex(a => a.questionType === 'geometry');
        if (geometryQuestionIdx === -1) {
          acc.push(formAttributes);
          return acc;
        }
        const newSections = [
          formAttributes.slice(0, geometryQuestionIdx),
          [formAttributes[geometryQuestionIdx]],
          formAttributes.slice(geometryQuestionIdx + 1)
        ]
        for (const section of newSections) {
          if (section.length > 0) {
            acc.push(section);
          }
        }
        return acc;
      }, [])
      .map((attr: CoreoAttribute[]): FormSection => {

        const blocks = attr.map((a: CoreoAttribute): FormBlock => {
          return {
            ...a,
            isVisible: true,
            error: null
          };
        });

        return {
          blocks,
          visible: true,
          completed: false,
          constraints: blocks.map(b => ValidationEngine.buildConstraints(b))
        };

      });

    const newForm: FormState = {
      ...form,
      attributes,
      uuid: Math.random().toString(36).substring(2),
      ready: false,
      finished: false,
      dirty: false,
      sectionId: 0,
      nextSectionId: null,
      prevSectionId: null,
      record: formRecord,
      sections,
      subformIdx,
      subformAttributeId,
      ...(state ?? {})
    };

    const { sections: processedSections, record: processedRecord } = processSections(newForm);

    return {
      ...newForm,
      sections: processedSections,
      record: {
        ...formRecord,
        data: {
          ...formRecord.data,
          ...processedRecord.data
        }
      },
      nextSectionId: getNextActiveSection(newForm.sectionId, processedSections),
      prevSectionId: getPrevActiveSection(newForm.sectionId, processedSections),
      ready: processedSections.every(section => section.completed)
    };
  };

  switch (action.type) {
    case TypeKeys.FORM_INITIALIZE: {
      const { form, attributes, projectId, record, subformIdx, subformAttributeId } = action;
      const newFormState = initForm(form, attributes, projectId, record, subformAttributeId, subformIdx);

      return {
        forms: [
          ...state.forms,
          newFormState
        ]
      };
    }

    case TypeKeys.FORM_HYDRATE: {
      const { form, attributes, projectId } = action;
      const newFormState = initForm(form, attributes, projectId, form.record, form.subformAttributeId, form.subformIdx, form);
      const idx = state.forms.findIndex(f => f.uuid === newFormState.uuid);
      return {
        forms: [
          ...state.forms.slice(0, idx),
          newFormState,
          ...state.forms.slice(idx + 1)
        ]
      };
    }

    case TypeKeys.FORM_RESET: {
      const [currentForm] = state.forms.slice(-1);

      const record: CoreoRecord = action.record ?? {
        ...emptyRecord(),
        projectId: currentForm.record.projectId,
        formId: currentForm.record.formId,
        surveyId: currentForm.record.surveyId,
        id: null
      };

      const resetForm = initForm(currentForm, currentForm.attributes, currentForm.record.projectId, record, currentForm.subformAttributeId, currentForm.subformIdx);

      return {
        forms: [
          ...state.forms.slice(0, -1),
          resetForm
        ]
      };
    }

    case TypeKeys.FORM_ADD_RECORD_ATTACHMENT: {

      return updateCurrentRecord(state, current => {
        return {
          attachments: [
            ...current.attachments,
            action.attachment
          ]
        };
      });
    }

    case TypeKeys.FORM_REPLACE_RECORD_ATTACHMENT: {

      return updateCurrentRecord(state, current => {
        const idx = current.attachments.findIndex(a => a.id === action.attachment.id);
        return {
          attachments: [
            ...current.attachments.slice(0, idx),
            action.attachment,
            ...current.attachments.slice(idx + 1)
          ]
        };
      });
    }

    case TypeKeys.FORM_REMOVE_RECORD_ATTACHMENT: {
      return updateCurrentRecord(state, current => {
        const idx = current.attachments.findIndex(a => a.id === action.attachment.id);
        return {
          attachments: [
            ...current.attachments.slice(0, idx),
            ...current.attachments.slice(idx + 1)
          ]
        };
      })
    }

    case TypeKeys.FORM_UPDATE_RECORD_DATA:
    case TypeKeys.FORM_UPDATE_RECORD_GEOMETRY: {

      const { type } = action;
      return updateCurrentRecord(state, current => {
        switch (type) {
          case TypeKeys.FORM_UPDATE_RECORD_DATA: {
            const { path, value } = action as any;
            return {
              data: {
                ...current.data,
                [path]: value
              }
              // associates: current.associates
            };
          }
          case TypeKeys.FORM_UPDATE_RECORD_GEOMETRY: {
            const { geometry } = action as FormsUpdateRecordGeometryAction;
            const box = geometry ? bbox(geometry) : null;

            return {
              geometry,
              feature: geometry ? {
                type: 'Feature',
                geometry,
                bbox: box,
                properties: {}
              } : null
            };
          }
        }
      });
    }

    /**
     * Move the form forward
     */
    case TypeKeys.FORM_NEXT: {
      const [currentForm] = state.forms.slice(-1);

      const nextSectionId = currentForm.nextSectionId;
      const newNextSectionId = getNextActiveSection(nextSectionId, currentForm.sections);
      const newPrevSectionId = getPrevActiveSection(nextSectionId, currentForm.sections);

      const updatedForm = {
        ...currentForm,
        sectionId: nextSectionId,
        nextSectionId: newNextSectionId,
        prevSectionId: newPrevSectionId
      };
      // Run Process Sections to initiate any data
      const { record, sections } = processSections(updatedForm);

      return {
        forms: [
          ...state.forms.slice(0, -1),
          {
            ...updatedForm,
            record,
            sections,
            nextSectionId: getNextActiveSection(updatedForm.sectionId, sections),
            prevSectionId: getPrevActiveSection(updatedForm.sectionId, sections),
            ready: sections.every(section => section.completed)
          }
        ]
      };
    }

    /**
     * Move the form back
     */
    case TypeKeys.FORM_PREV: {
      const [currentForm] = state.forms.slice(-1);
      const prevSectionId = currentForm.prevSectionId;
      const newNextSectionId = getNextActiveSection(prevSectionId, currentForm.sections);
      const newPrevSectionId = getPrevActiveSection(prevSectionId, currentForm.sections);

      return {
        forms: [
          ...state.forms.slice(0, -1),
          {
            ...currentForm,
            sectionId: prevSectionId,
            nextSectionId: newNextSectionId,
            prevSectionId: newPrevSectionId
          }
        ]
      };
    }

    case TypeKeys.FORM_CLEAR_DIRTY: {
      const [currentForm] = state.forms.slice(-1);
      return {
        forms: [
          ...state.forms.slice(0, -1),
          {
            ...currentForm,
            dirty: false
          }
        ]
      };
    }

    case TypeKeys.FORM_DISMISS: {
      return {
        forms: state.forms.slice(0, -1)
      };
    }

    case TypeKeys.FORM_COMPLETE: {
      const currentForm = state.forms[state.forms.length - 1];
      const forms = state.forms.slice(0, -1);

      if (!currentForm.subformAttributeId) {
        return { forms };
      }

      // If we are adding a record
      if (typeof currentForm.subformIdx === 'undefined') {
        return updateCurrentRecord({ forms }, current => {
          return {
            associates: {
              ...current.associates,
              [currentForm.subformAttributeId]: [
                ...(current.associates[currentForm.subformAttributeId] || []),
                currentForm.record
              ]
            }
          };
        });
      }
      return updateCurrentRecord({ forms }, current => {
        return {
          associates: {
            ...current.associates,
            [currentForm.subformAttributeId]: [
              ...current.associates[currentForm.subformAttributeId].slice(0, currentForm.subformIdx),
              currentForm.record,
              ...current.associates[currentForm.subformAttributeId].slice(currentForm.subformIdx + 1)
            ]
          }
        };
      });
    }

    case TypeKeys.FORM_FINISHED: {
      const [currentForm] = state.forms.slice(-1);
      return {
        forms: [
          ...state.forms.slice(0, -1),
          {
            ...currentForm,
            finished: true
          }
        ]
      };
    }

    case TypeKeys.FORM_DISMISS_ALL: {
      return { forms: [] };
    }

    case TypeKeys.FORM_ADD_RECORD_ASSOCIATE:
    case TypeKeys.FORM_ADD_RECORD_ASSOCIATED_TO: {

      const { associationAttributeId, record } = action;
      return updateCurrentRecord(state, current => {
        return {
          associates: {
            ...current.associates,
            [associationAttributeId]: [
              ...(current.associates[associationAttributeId] || []),
              record
            ]
          }
        };
      });
    }

    case TypeKeys.FORM_UPDATE_RECORD_ASSOCIATE: {
      const { associationAttributeId, record, index } = action;

      return updateCurrentRecord(state, current => {
        return {
          associates: {
            ...current.associates,
            [associationAttributeId]: [
              ...current.associates[associationAttributeId].slice(0, index),
              record,
              ...current.associates[associationAttributeId].slice(index + 1)
            ]
          }
        };
      });
    }

    case TypeKeys.FORM_REMOVE_RECORD_ASSOCIATE: {
      const { associationAttributeId, index, attributes } = action;

      return updateCurrentRecord(state, current => {

        // If the associate is pending creation, just immediately remove it
        const existingAssociate = current.associates[associationAttributeId][index];
        if (existingAssociate.syncState === CoreoRecordSyncStatus.PENDING_UPDATE && existingAssociate.id === null) {
          return {
            associates: {
              ...current.associates,
              [associationAttributeId]: [
                ...current.associates[associationAttributeId].slice(0, index),
                ...current.associates[associationAttributeId].slice(index + 1)
              ]
            }
          }
        }

        const newAssociate: CoreoRecord = {
          ...existingAssociate,
          syncState: CoreoRecordSyncStatus.PENDING_DELETE,
          origSyncState: existingAssociate.syncState,
          associates: updateAssociationSyncState(existingAssociate.associates, attributes, CoreoRecordSyncStatus.PENDING_DELETE)
        };

        return {
          associates: {
            ...current.associates,
            [associationAttributeId]: [
              ...current.associates[associationAttributeId].slice(0, index),
              newAssociate,
              ...current.associates[associationAttributeId].slice(index + 1)
            ]
          }
        };
      });
    }


    case TypeKeys.FORM_RESTORE_RECORD_ASSOCIATE: {
      const { associationAttributeId, index, attributes } = action;

      return updateCurrentRecord(state, current => {

        const newAssociate: CoreoRecord = {
          ...current.associates[associationAttributeId][index],
          syncState: current.associates[associationAttributeId][index].origSyncState,
          origSyncState: null,
          associates: updateAssociationSyncState(current.associates[associationAttributeId][index].associates, attributes)
        };

        return {
          associates: {
            ...current.associates,
            [associationAttributeId]: [
              ...current.associates[associationAttributeId].slice(0, index),
              newAssociate,
              ...current.associates[associationAttributeId].slice(index + 1)
            ]
          }
        };
      });
    }

    case TypeKeys.FORM_CLONE_RECORD_ASSOCIATE: {

      const { associationAttributeId, index } = action;
      return updateCurrentRecord(state, current => {
        const clone = buildRecordClone(current.associates[associationAttributeId][index], current.updatedAt, current.state);
        return {
          associates: {
            ...current.associates,
            [associationAttributeId]: [
              ...current.associates[associationAttributeId].slice(0, index + 1),
              clone,
              ...current.associates[associationAttributeId].slice(index + 1)
            ]
          }
        }
      });
    }

    case TypeKeys.FORM_ADD_RECORD_ASSOCIATED_TO: {
      const { associationAttributeId, record } = action;
      return updateCurrentRecord(state, current => {
        return {
          associates: {
            ...current.associates,
            [associationAttributeId]: [
              ...(current.associates[associationAttributeId] || []),
              record
            ]
          }
        };
      });
    }

    case TypeKeys.FORM_REMOVE_RECORD_ASSOCIATED_TO: {
      const { associationAttributeId, index } = action;
      return updateCurrentRecord(state, current => {
        return {
          associates: {
            ...current.associates,
            [associationAttributeId]: [
              ...current.associates[associationAttributeId].slice(0, index),
              ...current.associates[associationAttributeId].slice(index + 1)
            ]
          }
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default reducer;
