import { capSQLiteSet } from "@capacitor-community/sqlite";
import center from "@turf/center";
import { Feature } from "geojson";
import { CoreoRecord, CoreoRecordSyncStatus } from "../../../types";
import AppDatabase from "../app-db.service";
import { SyncRecord } from "../app-sync.service";
import SQLDatabase, { SQLQueryBuilder } from "../sql.service";
import { AppDatabaseRepo } from "./base.db";

export interface AppDatabaseRecord {
  id: number;
  createdAt: string;
  updatedAt: string;
  timestamp: number;
  projectId: number;
  surveyId: number;
  userId: number;
  deletedAt: string;
  syncState: CoreoRecordSyncStatus;
  origSyncState: CoreoRecordSyncStatus;
  syncError: string;
  geometryCenterLat: number;
  geometryCenterLng: number;
  geometry: any;
  state: number;
}

export class AppDatabaseRecordRepo extends AppDatabaseRepo<AppDatabaseRecord, SyncRecord, CoreoRecord> {

  tableName = 'records';
  columns = [
    ['id', 'INTEGER NOT NULL'],
    ['projectId', 'INTEGER NOT NULL'],
    ['createdAt', 'TEXT'],
    ['updatedAt', 'TEXT'],
    ['timestamp', 'INTEGER NOT NULL'],
    ['deletedAt', 'TEXT'],
    ['syncState', 'INTEGER NOT NULL'],
    ['syncError', 'TEXT'],
    ['surveyId', 'INTEGER NOT NULL'],
    ['userId', 'INTEGER NOT NULL'],
    ['state', 'INTEGER NOT NULL'],
    ['geometry', 'TEXT'],
    ['geometryCenterLng', 'REAL'],
    ['geometryCenterLat', 'REAL'],
    ['origSyncState', 'INTEGER']
  ];
  primaryKey = ['id'];
  references = [];
  indexes = [];
  // indexes

  public toInsertSet(record: CoreoRecord): capSQLiteSet[] {
    const c = record.geometry && center(record.geometry as any);
    // TODO cache this
    const dataColumns = AppDatabase.instance.formDataColumnMap.get(record.surveyId);
    const data = dataColumns.map(path => {
      const p = record.data?.[path];
      if (typeof p === 'undefined') {
        return null;
      }

      return Array.isArray(p) ? JSON.stringify(p) : p;
    });
    const columnsList = ["id", ...dataColumns].map(c => `"${c}"`).join(',');
    const valuesList = ["id", ...dataColumns].map(() => '?').join(',');

    return [{
      statement: `INSERT OR REPLACE INTO records ("id","projectId","createdAt","updatedAt","timestamp","deletedAt","syncState","syncError","surveyId","userId","state","geometry","geometryCenterLng","geometryCenterLat") VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?)`,
      values: [
        record.id,
        record.projectId,
        record.createdAt,
        record.updatedAt,
        record.timestamp,
        record.deletedAt,
        record.syncState ?? CoreoRecordSyncStatus.SUBMITTED,
        null,
        record.surveyId ?? 0,
        record.userId,
        record.state,
        record.geometry ? JSON.stringify(record.geometry) : null,
        c?.geometry?.coordinates?.[0] ?? null,
        c?.geometry?.coordinates?.[1] ?? null,
      ]
    }, {
      statement: `INSERT OR REPLACE INTO records_${record.surveyId} (${columnsList}) VALUES (${valuesList})`,
      values: [
        record.id,
        ...data
      ]
    }];
  }

  async deserialise(record: AppDatabaseRecord): Promise<CoreoRecord> {
    const { id, createdAt, projectId, updatedAt, deletedAt, surveyId, userId, syncState, syncError, geometry, geometryCenterLng, geometryCenterLat, state, timestamp, origSyncState } = record;
    if (!surveyId) {
      console.warn('MISSING ', record);
    }
    const data = await this.getRecordData(id, surveyId);

    return {
      id,
      createdAt,
      updatedAt,
      deletedAt,
      surveyId,
      formId: surveyId,
      associates: {},
      timestamp,
      attachments: [],
      projectId,
      syncState,
      syncError,
      origSyncState,
      state,
      userId,
      geometry: geometry && JSON.parse(geometry),
      data,
      geometryCenter: geometry && {
        type: 'Point',
        coordinates: [geometryCenterLng, geometryCenterLat]
      },
    };
  }

  public async getRecordData(recordId: number, formId: number) {
    const query = SQLDatabase.select().from(`records_${formId}`)
      .where('id = ?', recordId);
    const result = await SQLDatabase.instance.query<any>(query);
    if (result.length === 0) {
      return {};
    }
    const { id, ...data } = result[0];
    return data;
  }

  public async getRecordChildren(record: CoreoRecord, recursive: boolean = true): Promise<CoreoRecord[]> {

  // Children are defined by attributes on the record's survey with a questionType of "child"
    const query = SQLDatabase.select()
      .from('attributes')
      .field('id')
      .field('surveyId')
      .where('projectId = ?', record.projectId)
      .where('questionType = ?', 'child');

    const childAttributes = await SQLDatabase.instance.query<any>(query);

    const findChildren = async (record: CoreoRecord, output: CoreoRecord[] = []): Promise<CoreoRecord[]> => {
      // Find child attributes for this record
      const attributes = childAttributes.filter(a => a.surveyId === record.surveyId);

      if (attributes.length === 0) {
        return output;
      }

      const associationsQuery = SQLDatabase.select()
        .from('associations')
        .field('targetId')
        .where('sourceId = ?', record.id)
        .where('attributeId IN ?', attributes.map(a => a.id));

      const result = await SQLDatabase.instance.query<any>(associationsQuery);
      if (result.length === 0) {
        return output;
      }
      const ids = result.map(r => r.targetId);
      const childRecords = await (await this.search(q => q.where('id IN ?', ids)).toArray()).map(r => ({ ...r, parentId: record.id }));
      output.push(...childRecords.map(a => ({ ...a, parentId: record.id })));
      if (recursive) {
        for (const child of childRecords) {
          await findChildren(child, output);
        }
      }
      return output;
    }
    return findChildren(record);
  }

  public async getRecordAssociations(record: CoreoRecord): Promise<CoreoRecord[]> {
    const query = SQLDatabase.select()
      .from('attributes')
      .field('id')
      .field('surveyId')
      .where('projectId = ?', record.projectId)
      .where('questionType = ?', 'association');
    const associationAttributes = await SQLDatabase.instance.query<any>(query);

    if (associationAttributes.length === 0) {
      return [];
    }

    const associationsQuery = SQLDatabase.select()
      .from('associations')
      .field('targetId')
      .field('sourceId')
      .where('sourceId = ? OR targetId = ?', record.id, record.id)
      .where('attributeId IN ?', associationAttributes.map(a => a.id));

    const result = await SQLDatabase.instance.query<any>(associationsQuery);
    if (result.length === 0) {
      return [];
    }

    const ids = result.map(r => r.targetId === record.id ? r.sourceId : r.targetId);
    const records = await this.search(q => q.where('id IN ?', ids)).toArray();
    return records;
  }


  public async getImmediateParent(recordId: number): Promise<CoreoRecord> {
    const query = SQLDatabase.select()
      .from('associations')
      .join('attributes', 'attribute', 'attribute.id = associations.attributeId')
      .field('sourceId')
      .where('attribute.questionType = ?', 'child')
      .where('targetId = ?', recordId)
      .limit(1);

    const result = await SQLDatabase.instance.query<any>(query);
    if (result.length === 0) {
      return null;
    }
    const parentId = result[0].sourceId;
    const r = await this.search(q => q.where('id = ?', parentId)).toArray();
    return r[0] || null;
  }

  public async getParent(recordId: number): Promise<CoreoRecord> {
    let p = await this.getImmediateParent(recordId);
    while (p) {
      let t = await this.getImmediateParent(p.id);
      if (!t) {
        break;
      }
      p = t;
    }
    return p;
  }

  private deserializeFeature(serialisedRecord: AppDatabaseRecord): Feature {
    const { id, geometry, geometryCenterLat, geometryCenterLng, ...properties } = serialisedRecord
    return {
      type: 'Feature',
      geometry: JSON.parse(geometry),
      id,
      properties: {
        id,
        center: {
          type: 'Point',
          coordinates: [geometryCenterLng, geometryCenterLat]
        },
        ...properties
      }
    };
  }

  async findProjectMapFeatures(queryBuilder: SQLQueryBuilder): Promise<Feature[]> {

    const query = queryBuilder(SQLDatabase.select().from(this.tableName)
      .field(`${this.tableName}.id`)
      .field(`${this.tableName}.geometry`)
      .field(`${this.tableName}.geometryCenterLng`)
      .field(`${this.tableName}.geometryCenterLat`)
      .field(`${this.tableName}.syncState`)
      .field(`${this.tableName}.surveyId`)
      .where(`${this.tableName}.geometry IS NOT NULL`)
    );

    try {
      const features = [];
      // We query the DB directly so we don't deserialise the records first
      for await (const batch of SQLDatabase.instance.queryAll(query, 1000)) {
        features.push(...batch.map(mf => this.deserializeFeature(mf as any)));
      }
      return features;
    } catch (e) {
      console.warn(e);
      return [];
    }
  }

  public async getPendingUpdates(projectId: number): Promise<CoreoRecord[]> {
    return this.search(query => query
      .where('syncState = ?', 1)
      .where('projectId = ?', projectId)).toArray();
  }


  public async getPendingUpdateIds(projectId: number, surveyIds: number[] = []): Promise<number[]> {
    const pendingQuery = this.selectQuery()
      .field('id')
      .where('syncState IN ?', [CoreoRecordSyncStatus.PENDING_UPDATE, CoreoRecordSyncStatus.PENDING_DELETE])
      .where('projectId = ?', projectId)
      .order('id', true);

    if (surveyIds.length > 0) {
      pendingQuery.where('surveyId IN ?', surveyIds);
    }

    const ids: number[] = [];

    const query = await SQLDatabase.instance.queryAll(pendingQuery);
    for await (const records of query) {
      ids.push(...records.map(r => r.id));
    }
    return ids;
  }

}
