import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { modalController } from '@ionic/core';
import mime from 'mime-types';

import { CoreoRecordAttachment } from '../types';
import { CoreoAPI } from './api.service';

import { presignedUrl } from './coreo/coreo.queries';
import { readFileAsURL } from './db/filesystem.service';

const getExtension = (mimeType: string) => {
  // mime.extension returns false for 'image/jpg' so we have to do this extra check
  // also make sure we return the 3 character extension to support windows users
  if (mimeType === 'image/jpg' || mimeType === 'image/jpeg') {
    return 'jpg';
  }
  return mime.extension(mimeType);
}

const getPreSignedUrl = async (projectId: number, fileName: string, contentType: string, signal: AbortSignal) => {
  const presignedUrlResponse = await CoreoAPI.instance.graphql(presignedUrl, {
    projectId,
    fileName,
    contentType
  }, signal);

  return presignedUrlResponse.data.url;
}

const uploadAttachmentData = async (projectId: number, attachment: CoreoRecordAttachment, data: string | Blob, signal: AbortSignal) => {
  const extension = getExtension(attachment.mimeType);
  const fileName = new Date().valueOf() + (extension ? `.${extension}` : '');
  const targetUrl = await getPreSignedUrl(projectId, fileName, attachment.mimeType, signal);

  const response = await fetch(targetUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': attachment.mimeType
    },
    body: data,
    signal
  });

  if (!response.ok) {
    throw new Error('Attachment upload failed');
  }

  return targetUrl.split('?')[0];
}

export const uploadAttachment = async (projectId: number, attachment: CoreoRecordAttachment, signal: AbortSignal): Promise<string> => {
  let body;
  if (Capacitor.isNativePlatform()) {
    const url = await readFileAsURL(attachment.fileLocation);
    body = await (await fetch(Capacitor.convertFileSrc(url))).blob();
  } else {
    const file = await Filesystem.readFile({
      path: attachment.fileLocation,
      directory: Directory.Data
    });
    body = file.data;
  }
  return uploadAttachmentData(projectId, attachment, body, signal);
}

export const uploadAttachmentClone = async (projectId: number, attachment: CoreoRecordAttachment, signal: AbortSignal): Promise<string> => {
  const body = await fetch(attachment.url).then(r => r.blob());
  return uploadAttachmentData(projectId, attachment, body, signal);
}

export const fetchAttachmentMetadata = async (url: string, signal: AbortSignal): Promise<{ mimeType: string, size: number }> => {
  const req = await fetch(url, {
    method: 'HEAD',
    credentials: 'omit',
    signal
  });
  return {
    mimeType: req.headers.get('Content-Type'),
    size: parseInt(req.headers.get('Content-Length'))
  };
}

export const presentRecordModal = async (id: number, props = {}): Promise<HTMLIonModalElement> => {
  const modal = await modalController.create({
    component: 'app-record',
    componentProps: {
      recordId: id,
      ...props
    }
  });
  modal.present();
  return modal;
}
